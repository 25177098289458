<template>
  <b-card>
    <validation-observer ref="changePasswordForm">
      <!-- form -->
      <b-form
        @submit.prevent="passwordUpdate"
      >
        <b-row class="justify-content-center">

          <ul>
            <li>Enter a password min 8 characters (must contain- 1 Uppercase, 1 Lowercase, 1 numeric, 1 symbol)</li>
            <li>Your new password must be different from previously used passwords</li>
          </ul>
          <!-- old password -->
          <b-col md="6">
            <b-form-group
              label="Old Password"
              label-for="oldPassword"
            >
              <validation-provider
                #default="{ errors }"
                name="Old Password"
                vid="oldPassword"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="oldPassword"
                    v-model="oldPassword"
                    name="oldPassword"
                    :type="passwordFieldTypeOld"
                    placeholder="Old Password"
                    :state="errors.length > 0 ? false:null"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconOld"
                      class="cursor-pointer"
                      @click="togglePasswordOld"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label-for="newPassword"
              label="New Password"
            >
              <validation-provider
                #default="{ errors }"
                name="New Password"
                vid="newPaddword"
                rules="required|password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="newPassword"
                    v-model="newPaddword"
                    :type="passwordFieldTypeNew"
                    name="new-password"
                    placeholder="New Password"
                    :state="errors.length > 0 ? false:null"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label-for="confirmPassword"
              label="Retype New Password"
            >
              <validation-provider
                #default="{ errors }"
                name="Retype Password"
                vid="retypePassword"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="confirmPassword"
                    v-model="retypePassword"
                    :type="passwordFieldTypeRetype"
                    name="retype-password"
                    placeholder="New Password"
                    :state="errors.length > 0 ? false:null"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              type="submit"
              :disabled="isSubmmiting"
            >
              Save changes
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              class="mt-1"
              :disabled="isSubmmiting"
            >
              Reset
            </b-button>
          </b-col>
        <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import AuthService from '@/@service/api/Auth.service'
import FemsToastrService from '@/@service/utils/FemsToastr.service'
import LocalStorageService from '@/@service/utils/LocalStorage.service'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      oldPassword: '',
      newPaddword: '',
      retypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      isSubmmiting: false,

      required,
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },

    passwordUpdate() {
      this.$refs.changePasswordForm.validate().then(success => {
        if (success) {
          this.isSubmmiting = true
          const params = {
            oldPassword: this.oldPassword,
            password: this.newPaddword,
            password_confirmation: this.retypePassword,
          }
          AuthService.changePassword(params).then(response => {
            const res = response.data
            this.isSubmmiting = false
            if (!res.status) {
              FemsToastrService.error(res.message)
              return
            }
            FemsToastrService.success(res.message)
            LocalStorageService.clear()
            this.$router.push({ name: 'auth-login' })
            // fitl@123456
            // $2y$10$PZAdywPXek8bGw5JI888QOi2HsWnRSRycg2LRmg6D7e2N24TvE7TW
            // Fitl@123456
            // $2y$10$CWSO21U7embfwdk8/K2GPezyF19VDL.WWsveTr1LhHmzhy3XE1mAu
          }).catch(
            error => {
              console.log(error)
              this.isSubmmiting = false
              FemsToastrService.error(error.message)
            },
          )
        }
      })
    },
  },
}
</script>
